html, body {

}
.pt-c-10 {
    padding-top: 10px;
}
.text-c-1 {
    color: #fff;
}
.text-c-2 {
    color: #2c82c4;
}
.text-c-3 {
    color: #badaf2;
}
.bg-2 {
    background: #2c82c4;
}
.bg-3 {
    background: #0f113d;
}
.pl-30 {
    padding-left: 30px;
}
.bg-wl-br {
    background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(15,17,61,1) 50%);
}
.bg-bl-wr {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(15,17,61,1) 50%, rgba(255,255,255,1) 50%);
}
.clr {
    clear: both;
}
.header {

}
.header-top {
    background: #badaf2;
}
.top-tel-phone {
    width: 100%;
    display: block;
}
.top-tel-phone a {
    background: #0f113d;
    color: #fff;
    text-decoration: none;
    padding: 4px;
    position: relative;
    display: inline-block;
}
.top-tel-phone a::before {
    content: '';
    width: 0; 
    height: 0; 
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent; 
    border-right:10px solid #0f113d; 
    position: absolute;
    left: -10px;
    top: 0;
}
.pt-100 {
    padding-top: 100px;
}
.pr-100 {
    padding-right: 100px;
}
.pl-100 {
    padding-left: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.ptrlb-100 {
    padding: 100px;
}
.pt-150 {
    padding-top: 150px;
}
.pr-150 {
    padding-right: 150px;
}
.pl-150 {
    padding-left: 150px;
}
.pb-150 {
    padding-bottom: 150px;
}
.ptrlb-150 {
    padding: 150px;
}
.pt-200 {
    padding-top: 200px;
}
.pr-200 {
    padding-right: 200px;
}
.pl-200 {
    padding-left: 200px;
}
.pb-200 {
    padding-bottom: 200px;
}
.ptrlb-200 {
    padding: 200px;
}
.header .updated-news { 
    padding-left: 100px;
    position: relative;
    padding-top: 3px;
}
.header .updated-news .updated-news-title{
    position: absolute;
    left: 0;
    top: 3px;
}
.header .carousel-item-wrap {
    padding-right: 50px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.header .carousel-control-prev {
    right: 16px;
    left: inherit;
}
.header .carousel-control-next, .header .carousel-control-prev{
    width: 50px;
}
.header .carousel-control-next-icon, .header .carousel-control-prev-icon{
    width: 1rem;
    height: 1rem;
    filter: invert(100%);
}
.header .navbar-brand img {
    height: 45px;
}  
.header .navbar-nav  .nav-item .ext-link {
    display: none;
}
.header .nav-ext-item {
    float: left;
    padding: 0;
    margin: 0;
} 
.header .nav-ext-item li {
    float: left;
    list-style: none;
    margin-left:5px;
}
.header .nav-ext-item li a {
    border: 1px solid #badaf2;
    padding: 6px 20px;
    border-radius: 30px;
    color: #0f113d;
}
.header .nav-ext-item li a:hover {
    background: #badaf2;
}
.banner-section .carousel-caption {
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(15,17,61,.7);

}
.banner-section .carousel-caption> h5 {
    position: absolute;
    top:40%;
    transform: translateY(-40%);
    right: 0;
    left: 0;
    margin: 0 auto;
}
.banner-section .carousel-caption >p {
    font-size: 30px;
    color: #badaf2;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    right: 0;
    left: 0;
    margin: 0 auto;
}
.section-title {
    text-align: center;
}
.section-title h6 {
    color: #2c82c4;
}
.section-title h2 {
    color: #0f113d;
}
.osc-section {
    background: #eeeff2;
}
.osc-section-list {
    padding: 0;
    margin: 0;
}
.osc-section-list li {
    list-style: none;
    float: left;
    width: 25%;
    
}
.osc-section-list li:hover > div {
 background: #badaf2;
 border-color: #2c82c4;
}
.osc-section-list li >div {
    margin:10px ;
    background: #fff;
}
.osc-section-list li >div >.icon-home >i {
    font-size: 55px;
    color: #2c82c4;
}
.osc-section-list li >div >h2.display-6
{
    font-size: 1.2rem;
    font-weight: bold;
}
.osc-section-list li >div >p {
    color: #2c82c4;
}
.osc-section-list li >div a{
    text-decoration: none;
}
.bct-section {

}
.bct-section .nav-tabs {
    border: none;
}
.bct-section .nav-tabs .nav-link {
border-radius: 2px;
border: 1px solid #badaf2;
background: #badaf2;
color: #0f113d;
margin: 5px;
}
.bct-section .nav-tabs .nav-link.active {
    background: #0f113d;
    border-color: #0f113d; 
    color: #badaf2;
}
.country-list {
    width: 100%;
    padding: 0;
    margin: 0;
}
.country-list li {
    float: left;
    width: 20%;
    list-style: none;
}
.country-list li a {
    text-decoration: none;
    background: #edeff1;
    width: 98%;
    padding:12px 5px;
    display: block;
    border: 1px solid #c5c5c5;
    border-radius: 2px;
    margin: 1% 2%;
    color: #0f113d;

}
.country-list li a img {
    width: 22px;
}
.cr-section {

}
.cr-section .carousel-control-next, .cr-section .carousel-control-prev {
    position: inherit;
    margin: 10px auto;
    border-radius:2px ;
    background: #eeeff2;
    height: 50px;
    width: 50px;
}
.cr-section .carousel-control-next-icon, .cr-section .carousel-control-prev-icon {
    width: 1rem;
    height: 1rem;
    filter: invert(100%);
}
.cr-section .carousel-control-next {
float: left;
}
.cr-section .carousel-control-prev {
    float: right;
}
.wcu {
    background: #0f113d;
    color: #badaf2;
}
.wcu .nav-tabs {
    border-bottom: none;
}
.wcu .nav-tabs {
    padding: 0;
    margin: 0;
}
.wcu .nav-tabs .nav-link {
    width: 100px;
    height: 100px;
    border-radius:100% ;
    background: #badaf2;
    border-color: #badaf2;
    color: #0f113d;
    margin: 10px;
    font-size: 2rem;
}
.wcu .nav-tabs .nav-link:hover,.wcu .nav-tabs .nav-link.active {
    color: #badaf2;
    background: #2c82c4;
    border-color: #2c82c4;
}
.wcu .Explore {
    color: #badaf2;
    text-decoration: none;
}
.kmd-text {
 font-size: 3.5rem;
 font-weight: bold;
 color: #0f113d;
}
.kmd-list {
padding: 0;
margin: 0;
}
.kmd-list li {
    float: left;
    list-style: none;
    padding:18px 10px;
}
.kmd-list li a {
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
    border: 1px solid #0f113d;
    border-radius: 25px;
    color: #0f113d;
}
.kmd-list li a:hover {
    background: #0f113d;
    color: #fff;
}
.footer {

}
.footer-bg-1 {
    background: #eeeff2;
}
.footer-bg-2 {
    background: #badaf2;
}
.fot-list-v-one {
    width: 100%;
}
.fot-list-v-one li {
    list-style: square;
    padding: 4px;
}
.fot-list-v-one li a {
    text-decoration: none;
    color: #0f113d;
}
.fot-list-h-one {
    width: 100%;
    padding: 0;
}
.fot-list-h-one li{
 float: left;
 padding: 4px;
 list-style: none;
}
.fot-list-h-one li a{ 
    text-decoration: none;
    padding: 10px ;
    border-radius: 2px;
    background: #badaf2;
    color: #0f113d;
}
.fot-list-h-one li a:hover {
    background: #3e8cc8;
    color: #badaf2;
}
.fot-list-h-two {
    width: 100%;
    float: right;
    text-align: right;
    margin: 0;
    padding: 0;
}
.fot-list-h-two li {
    list-style: none;
    display: inline-block; 
    padding: 10px;
}
.fot-list-h-two li a{
    text-decoration: none;
    color: #0f113d;
}
.fot-list-h-two li a:hover{

}
.fot-list-media{
    width: 100%;
    padding: 0;
}
.fot-list-media li{
    float: left;
    padding: 10px;
    list-style: none;
}
.fot-list-media li a {
    width: 40px;
    height: 40px;
    text-decoration: none;
    background: #badaf2;
    border-radius: 2px;
    display: inline-block;
    position: relative;

}
.head-off {
    font-weight: bold;
}
.fot-list-media li a i {
    position: absolute;
    right: 0; 
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center; 
}
.fot-list-media li a:hover{
    background: #3e8cc8;
    color: #badaf2;
}
.fot-call-phone {
    font-size: 2rem;
    font-weight: bold;
    text-decoration: none;
    padding: 10px 0;
    display: inline-block;
}
.inner-banner-section {
    position: relative;
}
.inner-banner-caption {
    position: absolute;
    left: 0;
    top:50%;
    transform: translateY(-50%);
    padding:0 100px;
    color: #badaf2;
    z-index: 999;
}
.inner-banner-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(15, 17, 61, 0.7);
    z-index: 99;
}
.inner-banner-caption-faq {
    position: absolute;
    left: 0;
    top:40%;
    transform: translateY(-50%);
    padding: 100px;
    width: 50%;
}
.inner-banner-caption >p {
    font-size: 20px;
   
}
.c-box {
    padding: 20px;
    background: #fff;
}
.c-box >i {
    font-size: 60px;
    color: #2c82c4;
    padding: 10px 0;
    display: inline-block;
}
.c-box:hover {
    background: #badaf2;
}
.team-box {
    position: relative;
    border: 1px solid #2c82c4;
}
.team-box >img {
    width: 100%;
    overflow: hidden;
}
.team-box-name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #2c82c4;
    color: #fff;
    text-align: center;
    padding: 5px;
}
.team-box-name >h6 {
    padding: 0;
    margin: 0;
}
.institutions-banner-section {
    position: relative;
    background: url(../../assets/Institutions.png);
    padding: 50px 0;
}
.in-banner-layer {
    background-color: rgba(15, 17, 61, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.video-frame {
    z-index: 99;
    position: relative;
}
.video-content {
    z-index: 99;
    position: relative;
}
.count-box  {
    border-radius: 4px;
    background: #0f113d;
    color: #fff;
    padding: 10px;
    margin: 5px;
}
.count-box >h4 {
    margin: 0;
}
.img-box-1 {
    position: relative;
}
.img-box-1-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    background: #2c82c4;
    color: #fff;
    padding: 10px;
    text-align: center;
}
.arrow-v-list-1 {
    padding: 0;
    margin: 0;
}
.arrow-v-list-1 li {
    padding: 5px 0 5px 20px ;
    list-style: none;
    position: relative;
}
.arrow-v-list-1 li::before {
    font-family: remixicon;
    content: '\f2e4';
    position: absolute;
    left: 0;
    top: 5px;
}
.common-section {
    position: relative;
    overflow: hidden;
}
.half-img-right {
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
}
.half-img-bottom-right {
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    top: 37.6%;
}
.half-img-recruit-right {
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    top: 10%;
    padding-left: 5rem;
}
.time-common {
    padding-left: 8%;
}
.time-common1 {
    padding-left: 9%;
}
.time {
    padding-left: 4%;
}
.time-common2 {
    padding-left: 7%;
}
.time-common3 {
    padding-left: 10.7%;
}
.half-img-franchise-right {
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    top: 13%;
    padding-left: 5rem;
}

.accordion-flush>.accordion-item>.accordion-header .accordion-button {
    background: #2c82c4;
    color: #fff;
}
.accordion-flush>.accordion-item>.accordion-header .accordion-button.collapsed {
    background: #fff;
    color: #212529;
}
.left-nav-list {
    background: #fff;
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 999999;
}
.left-nav-list li {
    list-style: none;
}
.left-nav-list li a {
    text-decoration: none;
    padding: 12px;
    display: block;
    color: #0f113d;
}
.left-nav-list li:nth-child(odd) a {
    background: #eeeff2;
}
.left-nav-list li a:hover {
    background: #0f113d;
    color: #fff;
}
.c-form-1-content>h4 {
    padding-left: 20px;
}
.c-form-1-content > ul {
    padding-left: 20px;
}
.c-form-1-content > ul li {
    list-style: none;
    padding: 10px;
    background: #badaf2; 
    margin-top: 2px;
    color: #0f113d;
    font-size: 20px;
}
.c-form-1-content > ul li>b {
    width: 150px;
    display: inline-block;
}
.c-form-1-content > ul li:nth-child(odd){
    background: #adcce4; 
}
.c-form-1-content > ul li:hover {
    background: #0f113d;
    color: #fff;
}
@media (min-width: 1200px) {
    .display-6 {
        font-size: 2rem;
    }
}
@media (max-width: 991px) { 
    .osc-section-list li {
        width: 50%;
    }
    .container {
        width: 100%;
        max-width: 100%;
    }
    .header .navbar-nav .nav-item .ext-link {
        display: block;
    }
    .nav-ext-item {
        display: none;
    }
}
@media (max-width: 767px) {
    .osc-section-list li {
        width: 50%;
    }
    .container {
        width: 100%;
    }
    .country-list li  {
        width: 33.3%;
    }
    .top-tel-phone {
        display: none;
    }
    .c-form-1.bg-wl-br {
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 50%);
    }
    .bg-bl-wr {
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 50%);
        color: #212529;
    }
.remove-pt-200-767  {
    padding-top: 0;
}
.remove-pt-150-767  {
    padding-top: 0;
}
.remove-pt-100-767 {
    padding-top: 0;
}
    .c-form-1 .d-flex {
        display: block!important;
    }
    .c-form-1 .c-form-1-content {
        color: #212529;
    }
    .c-form-1 .c-form-1-content .pl-30 {
        padding-left: 0;
    }
    
   
}
@media (max-width: 576px) {
    .osc-section-list li {
        width: 100%;
    }
    .country-list li  {
        width: 50%;
    }
    .fot-list-h-one li {
        width: 100%;
    }
    .fot-list-h-one li a {
        display: block;
    }
    .fot-list-h-two {
        float: left;
        text-align: center;
    }
    .copy-r-text {
        text-align: center;
    }
}

.radio-contact {
    display: flex;
    gap: 2%;
}